import * as React from "react";
import NextLink, { LinkProps } from "next/link";
import { UrlObject } from "url";
import { urlObjectAs } from "../../utils/routing/routing";
import { Link } from "@mui/material";
import { SxProps } from "@mui/system";
import { Theme } from "@mui/material/styles";
import { useRouter } from "next/router";

interface InternalLinkProps extends LinkProps {
    // https://nextjs.org/docs/api-reference/next/link#if-the-child-is-a-functional-component
    // functional child component must forward ref, both mui Button and Link are doing so...
    children: React.ReactNode;
    href: UrlObject;
}

// TODO allow to use only defined routes?
// TODO use render prop instead of children for passing active flag (similar to passHref), active check can be optionally passed as well
export const InternalLink = (p: InternalLinkProps) => {
    const router = useRouter();
    const as = p.as != null ? p.as : urlObjectAs(p.href, router);

    return (
        <NextLink {...p} as={as} passHref legacyBehavior>
            {p.children}
        </NextLink>
    );
};

interface ExternalLinkProps {
    children?: React.ReactNode;
    href?: string;
    sx?: SxProps<Theme>;
    download?: string;
}
export const ExternalLink = (p: ExternalLinkProps) => (
    <Link
        href={p.href}
        target="_blank"
        rel="noreferrer"
        sx={p.sx}
        download={p.download}
    >
        {p.children}
    </Link>
);
