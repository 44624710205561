import { UrlObject } from "url";
import { defineRoute, replaceQuerySegment } from "../utils/routing/routing";
import * as t from "io-ts";
import { NumberFromString } from "io-ts-types";
import { events } from "../domain/event";
import { formatWithValidation } from "next/dist/shared/lib/router/utils/format-url";
import { pipe } from "fp-ts/function";

export const homePageRoute = defineRoute(t.type({}), () => ({
    pathname: "/",
}));

export const xathloUrl = (href: UrlObject) => {
    // TODO from env?
    if (process.env.NODE_ENV == "development") {
        href.hostname = "localhost";
        href.port = 3000;
    } else {
        href.hostname = "xathlo.com";
        href.protocol = "https";
    }

    return pipe(href, replaceQuerySegment, formatWithValidation);
};

export const eventAttendRoute = defineRoute(
    t.type({
        eventId: NumberFromString.pipe(events.columns.id),
    }),
    (query) => ({
        pathname: `/event/[eventId]/attend`,
        query,
    })
);

export const eventAttendPurchasedRoute = defineRoute(
    t.type({
        eventId: NumberFromString.pipe(events.columns.id),
    }),
    (query) => ({
        pathname: `/event/[eventId]/thank-you`,
        query,
    })
);

export const privacyRoute = defineRoute(t.unknown, () => ({
    pathname: "/privacy",
}));
