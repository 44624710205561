import * as React from "react";

interface Props {
    height?: number | string;
    width?: number | string;
}

const XPath = () => (
    <g>
        <path d="M 81.988 0 L 64.896 18.759 L 75.837 29.809 L 103.665 0 L 81.988 0 Z" />
        <path d="M 43.291 0 L 105.17 68.458 L 84.24 68.458 L 62.578 44.482 L 41.547 68.458 L 20 68.458 L 51.787 32.666 L 21.563 0 L 43.291 0 Z" />
    </g>
);

export const LogoX = (p: Props) => (
    <svg
        viewBox="20 0 85.17 68.46"
        width={p.width}
        height={p.height}
        xmlns="http://www.w3.org/2000/svg"
        role="img"
    >
        <title>xathlo</title>
        <XPath />
    </svg>
);

export const LogoXathlo = (p: Props) => {
    const color = "#000";

    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="20 0 494.31 69.63"
            role="img"
            width={p.width}
            height={p.height}
        >
            <title>xathlo</title>
            <g
                id="svgGroup"
                strokeLinecap="round"
                fillRule="evenodd"
                strokeWidth="0"
                fill={color}
            >
                <XPath />
                <path d="M 194.678 68.311 L 187.988 68.311 L 178.76 50.635 L 133.203 50.635 L 123.975 68.311 L 117.285 68.311 L 152.393 1.221 L 159.57 1.221 L 194.678 68.311 Z M 175.781 45.02 L 156.006 7.129 L 136.182 45.02 L 175.781 45.02 Z" />
                <path d="M 255.566 6.787 L 225.049 6.787 L 225.049 68.311 L 218.994 68.311 L 218.994 6.787 L 188.477 6.787 L 188.477 1.221 L 255.566 1.221 L 255.566 6.787 Z" />
                <path d="M 341.064 68.311 L 334.961 68.311 L 334.961 35.791 L 274.854 35.791 L 274.854 68.311 L 268.75 68.311 L 268.75 1.221 L 274.854 1.221 L 274.854 30.127 L 334.961 30.127 L 334.961 1.221 L 341.064 1.221 L 341.064 68.311 Z" />
                <path d="M 418.799 68.311 L 393.959 68.458 C 369.745 68.458 363.649 68.458 363.608 44.872 L 363.672 1.221 L 366.724 1.221 L 369.775 1.221 L 369.745 44.981 C 369.745 62.684 374.47 62.684 393.664 62.793 L 418.799 62.598 L 418.799 65.455 L 418.799 68.311 Z" />
                <path d="M 514.307 34.815 Q 514.307 43.848 511.035 50.415 Q 507.764 56.983 502.051 61.255 Q 496.338 65.528 488.574 67.578 A 62.026 62.026 0 0 1 477.322 69.429 A 73.977 73.977 0 0 1 471.826 69.629 A 70.571 70.571 0 0 1 461.012 68.828 A 59.828 59.828 0 0 1 454.98 67.578 A 42.393 42.393 0 0 1 446.426 64.36 A 35.617 35.617 0 0 1 441.406 61.255 Q 435.645 56.983 432.373 50.415 Q 429.102 43.848 429.102 34.815 A 41.384 41.384 0 0 1 429.529 28.744 A 32.664 32.664 0 0 1 430.615 23.902 A 31.943 31.943 0 0 1 433.572 17.118 A 28.778 28.778 0 0 1 434.863 15.113 A 28.779 28.779 0 0 1 441.479 8.399 A 38.395 38.395 0 0 1 449.384 3.986 A 42.906 42.906 0 0 1 450.122 3.687 A 48.802 48.802 0 0 1 458.326 1.277 A 55.44 55.44 0 0 1 460.352 0.904 A 68.771 68.771 0 0 1 469.593 0.032 A 77.896 77.896 0 0 1 471.826 0 Q 480.811 0 488.574 2.051 A 41.855 41.855 0 0 1 497.071 5.27 A 35.189 35.189 0 0 1 502.051 8.374 Q 507.764 12.647 511.035 19.214 Q 514.307 25.782 514.307 34.815 Z M 508.008 34.815 A 37.252 37.252 0 0 0 507.464 28.298 A 28.117 28.117 0 0 0 505.688 22.217 Q 503.369 16.748 498.828 13.013 A 27.69 27.69 0 0 0 492.652 9.214 A 36.744 36.744 0 0 0 487.524 7.349 Q 480.762 5.42 471.826 5.42 A 69.926 69.926 0 0 0 464.414 5.794 Q 460.474 6.215 457.047 7.108 A 41.703 41.703 0 0 0 456.079 7.373 A 36.737 36.737 0 0 0 450.002 9.684 A 27.821 27.821 0 0 0 444.678 13.062 A 23.719 23.719 0 0 0 437.744 22.29 A 28.863 28.863 0 0 0 435.73 29.758 A 37.473 37.473 0 0 0 435.4 34.815 Q 435.4 41.944 437.744 47.412 Q 440.088 52.881 444.678 56.617 A 28.108 28.108 0 0 0 450.911 60.415 A 37.302 37.302 0 0 0 456.079 62.281 A 48.639 48.639 0 0 0 463.391 63.723 Q 467.341 64.209 471.826 64.209 A 70.534 70.534 0 0 0 479.229 63.84 Q 483.72 63.365 487.524 62.281 A 35.837 35.837 0 0 0 493.555 59.988 A 27.181 27.181 0 0 0 498.828 56.617 Q 503.369 52.881 505.688 47.412 A 28.633 28.633 0 0 0 507.618 40.383 A 38.151 38.151 0 0 0 508.008 34.815 Z" />
            </g>
        </svg>
    );
};
