import { table, TableRowT } from "../utils/pg-ts/pg-ts";
import { commonIdCodec, mdxInputCodec } from "./common";
import { NonEmptyString } from "io-ts-types";
import { subdomainCodec } from "../utils/subdomain/subdomain";
import { messages } from "../utils/i18n/i18n";
import MdxInput from "../components/form/MdxInput";

export const pageTable = table("pages", {
    id: commonIdCodec,
    title: NonEmptyString, // TODO some length restriction
    content: mdxInputCodec, // TODO some length restriction
});
export type PageRow = TableRowT<typeof pageTable>;
export const organizationPageListView = table("organization_page_list", {
    ...pageTable.columns,
    subdomain: subdomainCodec,
});

export const pageMessages = messages(
    {
        title: "Title",
        content: "Content",
    },
    {
        cs: {
            title: "Název",
            content: "Obsah",
        },
    }
);
