import * as React from "react";
import { Box, Container, Grid, Link } from "@mui/material";
import { ExternalLink, InternalLink } from "../common/Link";
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import { privacyRoute } from "../../routes/xathlo";
import { Email } from "../common/Email";

interface Props {
    children: React.ReactNode;
    breadCrumbs?: React.ReactNode;
    rightMenu?: React.ReactNode;
}

export default function Layout(p: Props) {
    return (
        <>
            <Box
                sx={{
                    minHeight: "95vh",
                    flexDirection: "column",
                    display: "flex",
                }}
            >
                <Container sx={{ py: 1 }}>
                    <Grid
                        container
                        justifyContent={"space-between"}
                        alignItems={"center"}
                        flexWrap={"nowrap"}
                    >
                        <Grid item sx={{ flex: "0 1 auto" }}>
                            {p.breadCrumbs}
                        </Grid>

                        <Grid
                            item
                            container
                            component={"nav"}
                            justifyContent={"end"}
                            sx={{ width: "auto" }}
                        >
                            {p.rightMenu}
                        </Grid>
                    </Grid>
                </Container>
                <Box
                    sx={{
                        flexGrow: 1,
                        backgroundColor: (theme) => theme.surfaceColor.topLevel,
                        borderBottom: 1,
                        borderTop: 1,
                        borderColor: (theme) => theme.borderColor,
                        display: "flex",
                        flexDirection: "column",
                    }}
                >
                    <Box
                        sx={{
                            py: 2,
                            flexGrow: 1,
                            display: "flex",
                            flexDirection: "column",
                        }}
                    >
                        {p.children}
                    </Box>
                </Box>
            </Box>
            <Container sx={{ py: 2 }}>
                <Grid container spacing={2}>
                    <Grid item>
                        <Email email={"hello@xathlo.com"} />
                    </Grid>
                    <Grid item>
                        <ExternalLink
                            href={"https://www.facebook.com/xathlocom"}
                        >
                            <FacebookIcon />
                        </ExternalLink>
                    </Grid>
                    <Grid item>
                        <ExternalLink href={"https://twitter.com/xathlocom"}>
                            <TwitterIcon />
                        </ExternalLink>
                    </Grid>
                    <Grid item>
                        <ExternalLink
                            href={"https://www.linkedin.com/company/xathlocom"}
                        >
                            <LinkedInIcon />
                        </ExternalLink>
                    </Grid>
                    <Grid item flexGrow={1} sx={{ textAlign: "right" }}>
                        <InternalLink href={privacyRoute.route({})}>
                            <Link variant={"body2"}>
                                ochrana osobních údajů
                            </Link>
                        </InternalLink>
                    </Grid>
                </Grid>
            </Container>
        </>
    );
}
