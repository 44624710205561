import * as t from "io-ts";
import * as React from "react";
import { Subdomain, subdomainCodec } from "../utils/subdomain/subdomain";
import { useRouter } from "next/router";
import { pipe } from "fp-ts/function";
import { array, option } from "fp-ts";
import { Option } from "fp-ts/Option";
import { useQuery } from "../utils/supabase/useQuery";
import {
    organizationIdCodec,
    organizationNameCodec,
} from "../domain/organization";
import { NumberFromString } from "io-ts-types";
import { useUser } from "../utils/supabase/useUser";
import { rightOrThrow } from "../utils/fp";
import { isCompletedState, QueryState } from "../utils/supabase/QueryState";
import { sequenceS } from "fp-ts/Apply";
import { defineRoute, queryArrayCodec } from "../utils/routing/routing";
import { events, raceTable } from "../domain/event";
import { columns } from "../utils/pg-ts/pg-ts";
import { pageTable } from "../domain/page";

const orgQueryCodec = t.type({
    org: subdomainCodec,
});

const eventQueryCodec = t.intersection(
    [
        orgQueryCodec,
        t.type({
            eventId: NumberFromString.pipe(events.columns.id),
        }),
    ],
    "EventQuery"
);
export type EventQuery = t.TypeOf<typeof eventQueryCodec>;

const orgBase = "/org/[org]";
const eventBasePath = `${orgBase}/event/[eventId]`;
const raceBaseRoute = `${eventBasePath}/race`;
const pagePathBase = `${orgBase}/page`;

export const orgRoute = {
    index: defineRoute(orgQueryCodec, (query) => ({
        pathname: `${orgBase}`,
        query,
    })),
    events: {
        create: defineRoute(orgQueryCodec, (query) => ({
            pathname: `${orgBase}/event/create`,
            query,
        })),
        detail: defineRoute(eventQueryCodec, (query) => ({
            pathname: eventBasePath,
            query,
        })),
        registrations: defineRoute(eventQueryCodec, (query) => ({
            pathname: `${eventBasePath}/registrations`,
            query,
        })),
        startList: defineRoute(eventQueryCodec, (query) => ({
            pathname: `${eventBasePath}/registrations`,
            query,
        })),
        results: defineRoute(
            t.intersection([
                eventQueryCodec,
                t.partial({
                    raceId: queryArrayCodec(
                        NumberFromString.pipe(raceTable.columns.id)
                    ),
                }),
            ]),
            (query) => ({
                pathname: `${eventBasePath}/results`,
                query,
            })
        ),
        timing: defineRoute(eventQueryCodec, (query) => ({
            pathname: `${eventBasePath}/timing`,
            query,
        })),
        resultsImport: defineRoute(eventQueryCodec, (query) => ({
            pathname: `${eventBasePath}/results/import`,
            query,
        })),
    },
    news: defineRoute(orgQueryCodec, (query) => ({
        pathname: `${orgBase}/news`,
        query,
    })),
    race: {
        create: defineRoute(eventQueryCodec, (query) => ({
            pathname: `${raceBaseRoute}/create`,
            query,
        })),
        edit: defineRoute(
            t.intersection(
                [
                    eventQueryCodec,
                    t.type({
                        raceId: NumberFromString.pipe(raceTable.columns.id),
                    }),
                ],
                "RaceDetailQuery"
            ),
            (query) => ({
                pathname: `${raceBaseRoute}/[raceId]`,
                query,
            })
        ),
    },
    clubs: defineRoute(orgQueryCodec, (query) => ({
        pathname: `${orgBase}/clubs`,
        query,
    })),
    athletes: defineRoute(orgQueryCodec, (query) => ({
        pathname: `${orgBase}/athletes`,
        query,
    })),
    pages: {
        list: defineRoute(orgQueryCodec, (query) => ({
            pathname: pagePathBase,
            query,
        })),
        create: defineRoute(orgQueryCodec, (query) => ({
            pathname: `${pagePathBase}/create`,
            query,
        })),
        edit: defineRoute(
            t.intersection(
                [
                    orgQueryCodec,
                    t.type({
                        pageId: NumberFromString.pipe(pageTable.columns.id),
                    }),
                ],
                "PageDetailQuery"
            ),
            (query) => ({
                pathname: `${pagePathBase}/[pageId]`,
                query,
            })
        ),
    },
};

export interface RedirectToBrand {
    readonly RedirectTo: unique symbol;
}

export const redirectToCodec = t.brand(
    t.string,
    (n): n is t.Branded<string, RedirectToBrand> => true, // TODO ends with slash?
    "RedirectTo"
);

export type RedirectTo = t.TypeOf<typeof redirectToCodec>;

export const login = defineRoute(
    t.type({ redirectTo: redirectToCodec }),
    (query) => ({
        pathname: "/login",
        query,
    })
);

export const dashboardRoute = defineRoute(t.type({}), () => ({
    pathname: "/dashboard",
}));

// api

const orgFields = {
    id: organizationIdCodec,
    name: organizationNameCodec,
    subdomain: subdomainCodec,
};

const orgItemCodec = t.type(orgFields, "EventListItem");
export type OrgDetails = t.TypeOf<typeof orgItemCodec>;
const organizationsCodec = t.array(orgItemCodec);

type OrganizationsWhereAdminState = QueryState<
    t.TypeOf<typeof organizationsCodec>
>;

export interface OrganizationState {
    subdomainFromRoute: Option<Subdomain>;
    organizations: OrganizationsWhereAdminState;
}

interface OrganizationsLoadedCtx {
    active: OrgDetails;
    all: Array<OrgDetails>;
}
export const organizationsLoadedCtx = (
    state: OrganizationState
): Option<OrganizationsLoadedCtx> =>
    pipe(
        {
            ...state,
            organizations: pipe(
                state.organizations,
                option.fromPredicate(isCompletedState)
            ),
        },
        sequenceS(option.Applicative),
        option.chain((ctx) =>
            pipe(
                ctx.organizations.data,
                array.findFirst(
                    (org) => org.subdomain === ctx.subdomainFromRoute
                ),
                option.map((active) => ({
                    active,
                    all: ctx.organizations.data,
                }))
            )
        )
    );

// hooks
export const useOrganizationState = (): OrganizationState => {
    const userO = useUser();
    const router = useRouter();

    const subdomainFromRoute = pipe(
        router.query,
        orgQueryCodec.decode,
        option.fromEither,
        option.map((query) => query.org)
    );

    const subdomainNullable = pipe(subdomainFromRoute, option.toNullable);

    const organizationsWhereAdmin = useOrganizationsWhereAdmin(
        option.isNone(userO)
    );

    const organizationInfo: OrganizationState = {
        subdomainFromRoute: subdomainFromRoute,
        organizations: organizationsWhereAdmin.state,
    };

    React.useEffect(() => {
        if (router.isReady) {
            if (option.isNone(userO)) {
                // redirect is better than display the login form directly - static render will use the login form
                const routeQuery = {
                    redirectTo: pipe(
                        router.asPath,
                        redirectToCodec.decode,
                        rightOrThrow
                    ),
                };
                router.push(login.route(routeQuery));
            } else if (
                subdomainNullable == null ||
                organizationsWhereAdmin.state.type === "error" ||
                (organizationsWhereAdmin.state.type === "completed" &&
                    option.isNone(organizationsLoadedCtx(organizationInfo)))
            ) {
                router.push("/404"); // TODO better page for not found
            }
        }
    }, [subdomainNullable, organizationsWhereAdmin.state.type, router.isReady]);

    return organizationInfo;
};

export const useOrganizationsWhereAdmin = (forceSkip: boolean) => {
    return useQuery(
        "organizationsWhereAdmin",
        (supabase) =>
            supabase
                .from("organizations_with_subdomain_where_admin") // TODO pg-ts typed view name
                .select(columns(orgFields)),
        organizationsCodec,
        undefined,
        forceSkip
    );
};
