import * as React from "react";
import { Link } from "@mui/material";

interface Props {
    email: string;
}

export const Email = (p: Props) => {
    return (
        <Link
            // TODO common mail link component with copy behaviour for dektop, on mobile is ok to open mail client
            href={`mailto:${p.email}`}
        >
            {p.email}
        </Link>
    );
};
